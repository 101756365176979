$font-size-base: 0.8125rem; // Set base to 13px according to designs (taking 16px as 1rem)

$primary: #cb0c9f;
$secondary: #275395;
$success: #98ec2d;
$info: #21d4fd;
$warning: #db0000;
$danger: #db0000;
$alert: #ed1b2e;
$light: #edf1f5;
$highlight: #146ec0;
$dark: #25214c;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #707984;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'alert': $alert,
  'light': $light,
  'highlight': $highlight,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-800,
  'gray-900': $gray-900,
);

// Create your own map
$custom-colors: (
  'dark-blue': #2d3579,
  'dark-gray': #393939,
  'purple': #623ea3,
  'dark-purple': #482780,
  'light-aqua': #1ddec0,
  'dark-aqua': hsl(193, 51%, 44%),
  'aqua': #1cb6a2,
  'secondary-light': #275395ba,
  'sky-blue': #2989fa,
  'transparent-white': rgba(255, 255, 255, 0.18),
  'cyan-blue': #2d3748,
  'black': #000000,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$breadcrumb-active-color: $gray-600;

$font-family-base: 'Sintony', sans-serif;

$position-values: (
  0: 0,
  3: 3%,
  5: 5%,
  6: 6%,
  10: 10%,
  15: 15%,
  25: 25%,
  28: 28%,
  30: 30%,
  50: 50%,
  75: 75%,
  90: 90%,
  100: 100%,
);

$enable-negative-margins: true;
