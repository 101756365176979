@import '~bootstrap/scss/mixins';

.bg-green-gradient {
  @include gradient-y(
    map-get($theme-colors, 'light-aqua'),
    map-get($theme-colors, 'dark-aqua'),
    30%,
    100%
  );
}

.bg-light-blue-gradient {
  background: linear-gradient(180deg, rgba(2, 104, 198, 0.87) 0%, rgba(196, 196, 196, 0) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
