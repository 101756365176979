.react-datepicker {
  border: none !important;
  border-radius: 1rem !important;
  box-shadow: 0px 2.5px 5.5px rgba(0, 0, 0, 0.05);

  .react-datepicker__day-name {
    margin: 1px;
    width: 2.5rem;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__header {
    background: none;
    border: none;
  }

  .react-datepicker__navigation-icon::before {
    margin-top: 10px;
  }

  .react-datepicker__day {
    border-radius: 0 !important;
    color: map-get($theme-colors, 'dark-gray') !important;
    font-size: 14px;
    height: 2.5rem;
    line-height: 2.5rem;
    margin: 1px;
    width: 2.5rem;

    &:hover {
      background-color: #f4ceec !important;
      border-radius: 50% !important;
    }

    &.react-datepicker__day--keyboard-selected {
      background: none;
    }

    &.react-datepicker__day--selected:not(.react-datepicker__day--in-selecting-range) {
      background-color: map-get($theme-colors, 'primary') !important;
      border-radius: 50% !important;
      color: white !important;
    }

    &.react-datepicker__day--outside-month {
      color: map-get($theme-colors, 'gray-600') !important;
    }

    &.react-datepicker__day--disabled {
      color: map-get($theme-colors, 'gray-500') !important;
    }

    &.react-datepicker__day--in-range {
      background: none !important;
    }

    &.react-datepicker__day--in-selecting-range {
      background-color: #f4ceec !important;
      box-shadow: -5px 0 0 #f4ceec, 5px 0 0 #f4ceec;

      &.react-datepicker__day--selecting-range-start,
      &.react-datepicker__day--selecting-range-end {
        background-color: map-get($theme-colors, 'primary') !important;
        box-shadow: none;
        color: white !important;
      }

      &.react-datepicker__day--selecting-range-start {
        border-radius: 50% 0 0 50% !important;

        &.react-datepicker__day--selecting-range-end {
          border-radius: 50% !important;
        }
      }

      &.react-datepicker__day--selecting-range-end {
        border-radius: 0 50% 50% 0 !important;
      }
    }
  }
}

.btn-close {
  opacity: 1 !important;
}

.modal-header {
  border-bottom: 2px solid $primary;
}

.color-form-group {
  height: 110px;

  option {
    @extend .bg-secondary-light;
  }
}

.navbar {
  height: 92px;
}

.form-check.form-switch {
  .form-check-input {
    height: 1.25rem;
    top: 0 !important;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn {
  text-transform: none !important;
}

label {
  font-size: 1rem;
  font-weight: 700;
}

.form-control {
  border: 1px solid map-get($theme-colors, 'gray-500');
  border-radius: 0.5rem;

  &:disabled {
    background: map-get($theme-colors, 'gray-400');
  }
}
