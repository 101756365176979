@font-face {
  font-family: 'Diazo MVBE';
  src: url(/assets/fonts/DiazoMVBExCond.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro Black';
  src: url(/assets/fonts/SourceSansPro-Black.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  src: url(/assets/fonts/SourceSansPro-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Sintony';
  font-weight: 700;
  src: url(/assets/fonts/Sintony-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Sintony';
  font-weight: 300 600;
  src: url(/assets/fonts/Sintony-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  src: url(/assets/fonts/Raleway-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'OctinCollegeBold';
  src: url(/assets/fonts/Octin-College-Bold.woff);
}

@import 'react-datepicker/dist/react-datepicker.css';

@import './variables.scss';
@import './animations.scss';
@import './backgrounds.scss';
@import './soft-ui/soft-ui-dashboard.scss';
@import './utilities.scss';
@import './overrides.scss';
