/*!

=========================================================
* Soft UI Dashboard Pro - v1.0.7
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// {{ site.product.name }} Core styling components

@import 'theme';

// Mixin
@import 'mixins/mixins';

// PRO Components
@import 'accordion';
@import 'backgrounds';
@import 'cards-extend';
@import 'components';
@import 'dark-version';
@import 'dropdown-extend';
@import 'floating-elements';
@import 'list-check';
@import 'misc-extend';
@import 'rtl-extend';
@import 'group-icon';

// Plugins
@import 'plugins/pro/plugins-extend';
