@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

.card-body {
  font-family: $font-family-base !important;
}

.flip-x {
  transform: scaleX(-1);
}

.position-md-fixed {
  @include media-breakpoint-down(lg) {
    position: fixed;
  }
}

$box-customizer: 2px 5px 7px 0 rgba(25, 90, 90, 1);

$utilities: map-merge(
  $utilities,
  (
    'viewport-height':
      map-merge(
        map-get($utilities, 'viewport-height'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'viewport-height'), 'values'),
              (
                75: 75vh,
                50: 50vh,
                25: 25vh,
              )
            ),
        )
      ),
    'width':
      map-merge(
        map-get($utilities, 'width'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'width'), 'values'),
              (
                2: 2%,
                10: 10%,
                35: 35%,
              )
            ),
        )
      ),
    'max-viewport-height': (
      property: max-height,
      class: max-vh,
      values: (
        100: 100vh,
        75: 75vh,
        50: 50vh,
        25: 25vh,
      ),
    ),
    'rounded-top': (
      property: border-top-left-radius border-top-right-radius,
      class: rounded-top,
      values: (
        null: $border-radius,
        0: 0,
        1: 0.25rem,
        2: 0.5rem,
        3: 0.75rem,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
        small: 0.875rem,
        medium: 1.875rem,
        big: 2.75rem,
      ),
    ),
    'rounded-bottom': (
      property: border-bottom-right-radius border-bottom-left-radius,
      class: rounded-bottom,
      values: (
        null: $border-radius,
        0: 0,
        1: 0.25rem,
        2: 0.5rem,
        3: 0.75rem,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
        small: 0.875rem,
        medium: 1.875rem,
        big: 2.75rem,
      ),
    ),
    'background-color': (
      property: background-color,
      class: bg,
      values:
        map-merge(
          $theme-colors,
          (
            'purple': map-get($theme-colors, 'purple'),
            'dark-purple': map-get($theme-colors, 'dark-purple'),
          )
        ),
    ),
    'shadow': (
      property: box-shadow,
      class: shadow,
      values: (
        null: $box-shadow,
        sm: $box-shadow-sm,
        lg: $box-shadow-lg,
        none: none,
        customizer: $box-customizer,
      ),
    ),
    'min-width': (
      property: min-width,
      class: min-w,
      values: (
        100: 100%,
      ),
    ),
    'rounded':
      map-merge(
        map-get($utilities, 'rounded'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'rounded'), 'values'),
              (
                3: 0.5rem,
                4: 0.75rem,
                5: 1rem,
                6: 1.25rem,
              )
            ),
        )
      ),
    'rounded-bottom-start': (
      property: border-bottom-left-radius,
      class: rounded-bottom-start,
      values: (
        null: $border-radius,
        1: $border-radius-sm,
        2: $border-radius,
        3: $border-radius-lg,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
      ),
    ),
    'rounded-bottom-end': (
      property: border-bottom-right-radius,
      class: rounded-bottom-end,
      values: (
        null: $border-radius,
        1: $border-radius-sm,
        2: $border-radius,
        3: $border-radius-lg,
        4: 1rem,
        5: 1.25rem,
        6: 1.5rem,
        7: 2rem,
      ),
    ),
    'white-space': (
      property: white-space,
      class: white-space,
      values: (
        pre: pre,
      ),
    ),
    'object-fit': (
      property: object-fit,
      class: object-fit,
      values: (
        contain: contain,
      ),
    ),
    'font-family': (
      property: font-family,
      class: font-family,
      values: (
        source-sans-pro: Source Sans Pro Black,
      ),
    ),
    'line-height': (
      property: line-height,
      class: line-height,
      values: (
        0: 0,
      ),
    ),
    'opacity': (
      property: opacity,
      class: opacity,
      values: (
        0: 0,
        25: 0.25,
        50: 0.5,
        75: 0.75,
        100: 1,
      ),
    ),
    'duration': (
      property: transition-duration,
      class: duration,
      values: (
        0: 0s,
        100: 0.1s,
        200: 0.2s,
        300: 0.3s,
        400: 0.4s,
        500: 0.5s,
      ),
    ),
    'transition-function': (
      property: transition-timing-function,
      class: transition,
      values: (
        ease: ease,
        ease-in: ease-in,
        ease-out: ease-out,
        ease-in-out: ease-in-out,
        linear: linear,
        cubic-bezier: cubic-bezier,
      ),
    ),
    'transition-property': (
      property: transition-property,
      class: transition,
      values: (
        all: all,
      ),
    ),
    'height':
      map-merge(
        map-get($utilities, 'height'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'height'), 'values'),
              (
                max-content: max-content,
              )
            ),
        )
      ),
    'border-style': (
      property: border-style,
      class: border-style,
      values: (
        solid: solid,
        dashed: dashed,
        dotted: dotted,
        double: double,
        groove: groove,
        hidden: hidden,
        inset: inset,
        none: none,
        outset: outset,
        ridge: ridge,
        initial: initial,
        inherit: inherit,
      ),
    ),
    'top':
      map-merge(
        map-get($utilities, 'top'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'top'), 'values'),
              (
                18: 18%,
              )
            ),
          responsive: true,
        )
      ),
    'end':
      map-merge(
        map-get($utilities, 'end'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'end'), 'values'),
              (
                15n: -15%,
                24n: -24%,
              )
            ),
          responsive: true,
        )
      ),
    'scrollbar-gutter': (
      property: scrollbar-gutter,
      class: scrollbar-gutter,
      values: (
        stable: stable,
        none: none,
      ),
    ),
    'rotate': (
      property: transform,
      class: 'rotate',
      values: (
        180: rotate(180deg),
      ),
    ),
    'z-index': (
      property: z-index,
      class: 'z-index',
      values: (
        dropdown: 1000,
        sticky: 1020,
        fixed: 1030,
        modal-backdrop: 1040,
        offcanvas: 1050,
        modal: 1060,
        popover: 1070,
        tooltip: 1080,
      ),
    ),
  )
);

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.white-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 35px;
  }
  &::-webkit-scrollbar-thumb {
    background: white;

    -moz-background-clip: padding; /* Firefox 3.6 */
    -webkit-background-clip: padding; /* Safari 4? Chrome 6? */
    background-clip: padding-box; /* Firefox 4, Safari 5, Opera 10, IE 9 */
    border: solid 3px transparent;
    border-radius: 35px;
  }
}

.pink-scrollbar {
  &::-webkit-scrollbar {
    background: #9d1da7;
    border-left: 15px solid #5f3c9e;
    height: 7px;
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #9d1da7;
    border: 5px solid transparent;
    border-radius: 35px;
    margin-left: 15px;
    margin-right: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ed3daa;
    border: 5px solid transparent;
    border-radius: 35px;
    border-right: 0;
    width: 15%;
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.translate-md-x-start {
  @include media-breakpoint-down(lg) {
    transform: translateX(0%);
    transition: all 0.3s ease;
  }
}

.translate-md-x-end {
  @include media-breakpoint-down(lg) {
    transform: translateX(-100%);
    transition: all 0.3s ease;
  }
}

.hover-background-primary {
  &:hover {
    background-color: map-get($theme-colors, 'primary') !important;
    border-radius: 0;
    color: white;
  }
}

@import '~bootstrap/scss/utilities/api';
