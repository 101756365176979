.menu-slide-enter {
  position: absolute;
  transform: translateY(110%);
  width: 100%;
}

.menu-slide-enter-active {
  transition: all 0.1s ease;
  transform: translateY(0%);
}

.menu-slide-exit {
  position: absolute;
  width: 100%;
}

.menu-slide-exit-active {
  transition: all 0.1s ease;
  transform: translateY(110%);
}
